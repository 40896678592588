<template>
  <!-- We only want to show this appendix when any of the inquiry_items has been handled (approved, rejected or postponed) in any of the material_items in the meeting -->
  <div v-if="summary.handled > 0" class="page-break-before">
    <div class="border-top border-top-card pt-4 mt-4 d-print-none" />

    <div class="d-none d-print-block">
      <h2>{{ meeting.title }} - #{{ meeting.number }}</h2>

      <h3 class="mb-3 text-muted">{{ meeting.company_title }}</h3>
    </div>

    <inquiries-table />

    <inquiries-summary />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InquiriesTable from "./InquiriesTable.vue";
import InquiriesSummary from "./InquiriesSummary.vue";

export default {
  components: {
    InquiriesTable,
    InquiriesSummary,
  },

  computed: {
    ...mapGetters({
      getSummary: "inquiries/getSummary",
      meeting: "material/meeting",
    }),

    summary() {
      return this.getSummary({ meeting: this.meeting });
    },
  },

  mounted() {
    this.checkAndLoadInquiries();
  },

  methods: {
    ...mapActions("inquiries", ["loadInquiries"]),

    checkAndLoadInquiries() {
      if (!this.platformEnabled("inquiries")) {
        return;
      }

      this.loadInquiries();
    },
  },
};
</script>
