<template>
  <div v-if="localReferences.length" class="mb-3">
    <h5>
      {{ $t("activerecord.models.task.other", company.locale) }}
    </h5>

    <table class="table mb-0">
      <thead>
        <tr>
          <th class="pt-0 pb-1">
            {{ $t("activerecord.models.task.one", company.locale) }}
          </th>

          <th class="col-shrink pt-0 pb-1">
            {{ $t("activerecord.attributes.task.done_at", company.locale) }}
          </th>

          <th class="col-shrink pt-0 pb-1">
            {{ $t("activerecord.attributes.task.due_at", company.locale) }}
          </th>

          <th class="col-shrink pt-0 pb-1">
            {{ $t("activerecord.attributes.task.assigned_to", company.locale) }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="reference in localReferences"
          :key="`reference-${reference.id}`"
        >
          <td>
            <a
              v-dompurify-html="truncateText(reference.task.description)"
              :href="url(`tasks/${reference.reference_id}`)"
              class="d-print-none"
            />

            <span
              v-dompurify-html="truncateText(reference.task.description)"
              class="d-none d-print-inline"
            />
          </td>

          <td class="col-shrink">
            <span v-if="!!reference.task.done_at">
              {{ $d(new Date(reference.task.done_at)) }}
            </span>
          </td>

          <td class="col-shrink">
            <span v-if="!!reference.task.due_at">
              {{ $d(new Date(reference.task.due_at)) }}
            </span>
          </td>

          <td class="col-shrink text-center">
            <user-avatar
              v-if="reference.task.user_id"
              :user="reference.task.user_id"
              class="d-print-none"
            />

            <p class="text-left m-0 d-none d-print-block">
              {{ getUserName(reference.task.user_id) }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-be-visible="observeVisibilityOptions" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const materialHelpers = createNamespacedHelpers("material");

import TextUtilities from "@/mixins/textUtilities";

const AUTO_READ_AFTER_SECONDS = 1 * 1000; // 1 second

export default {
  mixins: [TextUtilities],

  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },

  emits: ["read"],

  data() {
    return {
      observeVisibilityOptions: {
        callback: this.visibilityChanged,
        once: true,
        throttle: AUTO_READ_AFTER_SECONDS,
      },
    };
  },

  computed: {
    ...materialHelpers.mapGetters(["followUpReferences"]),

    localReferences() {
      return this.followUpReferences.filter(
        (reference) =>
          reference.item_id === this.itemId &&
          reference.reference_type === "Task"
      );
    },
  },

  methods: {
    async visibilityChanged(isVisible) {
      if (isVisible) {
        this.$emit("read");
      }
    },
  },
};
</script>
