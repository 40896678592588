<template>
  <div v-if="summary.handled > 0" id="inquiries-summary" class="my-3">
    <h4>
      {{
        $t(
          "components.meetings.material.follow_up_inquiries.summary",
          company.locale
        )
      }}
    </h4>

    <div
      v-for="(statusSummary, status) in summary.statuses"
      :key="status"
      class="d-flex align-items-center mb-1"
    >
      <i
        :class="['far fa-fw mr-1 mt-1 d-print-none', inquiryStatusIcon(status)]"
      />

      <p class="mb-0">
        {{
          $t(
            `views.companies.inquiries.minutes.${status}_inquiries_w_count`,
            summary.statuses[status].count,
            company.locale,
            { count: summary.statuses[status].count }
          )
        }}:

        <span
          v-for="(currencySummary, currency, index) in statusSummary.currencies"
          :key="currency"
        >
          <span v-if="index > 0" class="mx-1">/</span>

          <money-format
            v-if="status == 'approved'"
            class="font-weight-bold small"
            :currency="currency"
            :value="currencySummary.total_granted_value"
          />

          <money-format
            v-if="status == 'postponed' || status == 'rejected'"
            class="font-weight-bold small"
            :currency="currency"
            :value="currencySummary.total_requested_value"
          />
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MoneyFormat from "@/components/shared/MoneyFormat.vue";

export default {
  components: { MoneyFormat },

  data() {
    return {
      statuses: ["approved", "rejected", "postponed"],
    };
  },

  computed: {
    ...mapGetters({
      getSummary: "inquiries/getSummary",
      meeting: "material/meeting",
    }),

    summary() {
      return this.getSummary({ meeting: this.meeting });
    },
  },

  mounted() {
    this.checkAndLoadInquiries();
  },

  methods: {
    ...mapActions("inquiries", ["loadInquiries"]),

    inquiryStatusIcon(status) {
      const STATUS_ICONS = {
        approved: "fa-check",
        rejected: "fa-times",
        postponed: "fa-clock",
      };

      return STATUS_ICONS[status] || null;
    },

    checkAndLoadInquiries() {
      if (!this.platformEnabled("inquiries")) {
        return;
      }

      this.loadInquiries();
    },
  },
};
</script>
